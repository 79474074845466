.react-google-flight-datepicker .submit-button {
  display: none !important;
}
.react-google-flight-datepicker .icon-calendar {
  fill: #a20152 !important;
}
.react-google-flight-datepicker .date.is-focus::after {
  border: 2px solid #a20152 !important;
}
.react-google-flight-datepicker .day:hover::after {
  border: 2px solid #a20152 !important;
}
.react-google-flight-datepicker .day.selected::after {
  background-color: #a20152 !important;
}
.react-google-flight-datepicker .day.selected.hovered::after {
  background-color: #a20152 !important;
}
.react-google-flight-datepicker .day.selected:hover::after,
.react-google-flight-datepicker .day.hovered:hover::after {
  background-color: #a20152 !important;
}
.react-google-flight-datepicker .day .background-day.first-day {
  background: linear-gradient(to right, #fff, #fddae7) !important;
}
.react-google-flight-datepicker .day .background-day.last-day {
  background: linear-gradient(to left, #fff, #fddae7) !important;
}
.react-google-flight-datepicker .day.hovered .background-day {
  opacity: 1;
}
.react-google-flight-datepicker .day.hovered.end {
  background: linear-gradient(90deg, #fddae7 50%, #fff 50%) !important;
}
.react-google-flight-datepicker .day.hovered {
  background-color: #fddae7 !important;
}
.react-google-flight-datepicker .day.hovered.end {
  background: linear-gradient(90deg, #fddae7 50%, #fff 50%) !important;
}
.react-google-flight-datepicker .day.hovered:hover {
  background-color: #fddae7 !important;
}
.react-google-flight-datepicker .day.selected.hovered {
  background: linear-gradient(90deg, #fff 50%, #fddae7 50%) !important;
}
.react-google-flight-datepicker .day.selected.hovered.end {
  background: linear-gradient(90deg, #fddae7 50%, #fff 50%) !important;
}
.react-google-flight-datepicker .day.selected.hovered::after {
  background-color: #a20152 !important;
}
