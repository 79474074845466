:root {
  --appbarcolor: #a20152;
  --inputborder: #ff0080;
  --greencolor: #33cdbb;
  --greycolor: #aaa0a0;
  --inputcolor: #d62980;
}
body {
  line-height: 100%;
}

.activeLink {
  color: white;
  background-color: var(--appbarcolor);
  border-color: var(--appbarcolor);
}
.activeLink:hover {
  background-color: var(--inputcolor);
  border-color: var(--inputcolor);
}
.login-form {
  margin: auto;
  display: block;
  margin-top: 5%;
  padding: 8%;
}
.login-box {
  background-color: rgba(0, 0, 0, 0.7);
  width: 30%;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
}

.bodyLogin {
  height: 100%;
}

input.editable-field {
  max-width: 100px;
}

.actions span {
  cursor: pointer;
}

.login-error {
  color: #cd5c5c;
  font-weight: bold;
  margin-top: 5px;
}

li.username {
  color: white;
  font-weight: bold;
  margin-left: 10px;
  text-transform: capitalize;
}

.background {
  background: url('../src/img/background.png') fixed;
  background-position: center;
  background-size: cover;
  background-repeat: 'no-repeat';
  width: 100vw;
  height: 100vh;
}

.bigLogo {
  height: 20%;
  width: 20%;
}

.bigLogoSmall {
  height: 8%;
  width: 8%;
}

.buttonPrimaryColor {
  background-color: var(--appbarcolor);
  border: var(--inputborder) solid 1px;
  color: white;
}
.buttonPrimaryColor:hover {
  background-color: var(--inputborder);
  border: var(--appbarcolor) solid 1px;
}

.buttonClose {
  background-color: darkred;
  border: red solid 1px;
  color: white;
}
.buttonClose:hover {
  background-color: red;
  border: var(--appbarcolor) solid 1px;
  color: white;
}

.primaryColor {
  color: var(--appbarcolor);
}

.secondaryColor {
  color: var(--inputborder);
}

.whiteShadow {
  box-shadow: 0 0 5px 0 rgba(255, 255, 255);
}
.opacity9 {
  opacity: 0.9;
}

.boxDarkOpacity {
  background-color: rgba(0, 0, 0, 0.7);
}

.listBorder {
  border-color: seashell;
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .modal-close {
  animation: fadeOut 0.5s;
}

@keyframes fadeOut {
  from { opacity: 0; }
  to   { opacity: 1; }
} */

.modal-main {
  background: rgb(32, 31, 31);
  border-radius: 15px;
  height: 85%;
  margin: auto;
  margin-top: 7%;
  padding: 30px;
  width: 50%;
}

.modal-notification {
  color: white;
  height: auto;
  margin: auto;
  margin-top: 10%;
  padding: 30px;
  width: 50%;
}

.modal-content {
  max-height: 50vh;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 3%;
  overflow-y: scroll;
  max-height: 70%;
}

.modal-block {
  display: block;
}

.modal-title {
  color: white;
  font-weight: bold;
}

.inputWidth {
  width: 50%;
}

.MuiTablePagination-actions,
.MuiTablePagination-selectLabel,
.MuiTablePagination-selectRoot {
  display: none !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.center {
  position: absolute;
  top: 50%;
  left: 49%;
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 60%;
  left: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-top: 2px solid var(--appbarcolor);
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}

#mui-component-select-person {
  min-width: 250px !important;
}
.calendarButton {
  border: none;
  margin-right: 5%;
  padding-left: 0;
  background-color: transparent;
  color: grey;
}

.css-1ygcj2i-MuiTableCell-root {
  padding-top: 16px;
  padding-left: 1%;
  padding-right: 0px;
  padding-bottom: 2%;
}
